/**
 *
 * Created by andrewlamers on 2/15/17.
 */
function ButtonCellRenderer () {}

// gets called once before the renderer is used$client->authenticate($_GET['code']);
ButtonCellRenderer.prototype.init = function(params) {
    this.params = params;
    this.value = params.value;
    this.element = document.createElement('div');

    var regex = /\{(.+)\}/g;
    var match = regex.exec(this.params.text);
    this.params.text = null;

    if(match && typeof match[1] === "string" && this.params.data) {
        var text = _.get(this.params.data, match[1]);
        if(text) {
            this.params.text = text;
        }
    }

    var that = this;
    this.button = document.createElement('div');
    if(this.params.class) {
        this.button.className = this.params.class;
    } else {
        this.button.className = 'ui small button';
    }
    this.button.innerHTML = this.params.text;
    this.onClick = function(e) {
        var actionName = that.params.text;
        if(that.params.actionName) {
            actionName = that.params.actionName;
        }
        that.params.clickEvent = e;
        that.action(actionName, that.params);
    };
    this.button.addEventListener("click", this.onClick);

    this.element.appendChild(this.button);
};
ButtonCellRenderer.prototype.action = function(action, params) {
    var event = new CustomEvent(this.getEventName(action), {detail: params});
    document.dispatchEvent(event);
};

ButtonCellRenderer.prototype.getEventName = function(action) {
    return "on"+_.upperFirst(_.camelCase(action)) + 'Action';
};

// gets called once when grid ready to insert the element
ButtonCellRenderer.prototype.getGui = function() {
    return this.element;
};


// gets called whenever the user gets the cell to refresh
ButtonCellRenderer.prototype.refresh = function(params) {
    // set value into cell again
    //this.element.innerHTML = params.value;
    if(params.data._printed == 'printed') {
        this.button.className = 'ui small button green';
    } else if(params.data._printed == 'error') {
        this.button.className = 'ui small button red';
    } else {
        this.button.className = 'ui small button';
    }
};

// gets called when the cell is removed from the grid
ButtonCellRenderer.prototype.destroy = function() {
    this.button.removeEventListener("click", this.onClick);
};