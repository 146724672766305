function HTMLCellRenderer () {}

// gets called once before the renderer is used
HTMLCellRenderer.prototype.init = function(params) {
    this.value = params.value;
    this.element = document.createElement('div');
    this.element.innerHTML = this.value;
};

// gets called once when grid ready to insert the element
HTMLCellRenderer.prototype.getGui = function() {
    return this.element;
};

// gets called whenever the user gets the cell to refresh
HTMLCellRenderer.prototype.refresh = function(params) {
    // set value into cell again
    this.element.innerHTML = params.valueFormatted ? params.valueFormatted : params.value;
};