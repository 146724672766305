"use strict";

toastr.options.preventDuplicates = true;

$(document).ajaxComplete(function(event, xhr) {
    if(_.has(xhr, 'responseJSON') && _.has(xhr, 'status')) {
        if(_.has(xhr, 'responseJSON.messages') && _.isArray(xhr.responseJSON.messages)) {
            _.each(xhr.responseJSON.messages, function(message, index){
                if(xhr.status === 200) {
                    if(message.type === "success")
                        toastr.success(message.message);
                } else {
                    if(message.type === "error")
                        toastr.error(message.message);
                }

                if(message.type === "info")
                    toastr.info(message.message);
                if(message.type === "warning")
                    toastr.warning(message.message);

            });
        }
    }
});

$.fn.form.settings.rules.date = function(value, format = null) {
    return moment(value, format).isValid();
};

$.fn.form.settings.prompt.date = '{name} must be a valid date.';

function confirmModal(title, message, callback, failCallback) {
    $("#confirmModal").modal({
        onShow: function() {
            var m = $(this);
            m.find('[data-name=title]').html(title);
            m.find('[data-name=message]').html(message);
        },
        onHide: function() {
            var m = $(this);
            m.find('[data-name=title]').html('');
            m.find('[data-name=message]').html('');
        },
        onApprove: function() {
            if(callback()) {
                return true;
            } else {
                return false;
            }
        },
        onDeny: failCallback
    }).modal("show");
}

function dispatch(name, data) {
    var detail = {
        detail: data
    };

    var event = new CustomEvent(name, detail);
    document.dispatchEvent(event);
}

function Url(params) {
    if(!params) {
        params = window.location.search;
    }

    this.parts = {};
    this.params = new URLSearchParams(params);
    this.initParts();
    this.url = '';
    this.reset_params = false;
    this.except = [];
    this.previous_url = '';
    this.fromPop = false;
}

Url.prototype.initParts = function() {
    for(var part of this.params) {
        this.set(part[0], part[1]);
    }
};

Url.prototype.getValue = function(key, noArray) {
    var value = '';
    if(this.params.has(key)) {
        value = this.params.get(key);
    }

    if(!noArray) {
        value = value.split(",");
    }

    return value;
};

Url.prototype.get = function(key) {
    return this.parts[key];
};
Url.prototype.reset = function(except) {
    this.reset_params = true;
    this.except = except;
};

Url.prototype.remove = function(key, value) {
    var that = this;
    _.each(this.parts[key], function(v, k){
        if(v == value) {
            that.parts[key][k] = undefined;
        }
    });
};

Url.prototype.set = function(key, value, push) {

    if (push) {
        if (!this.parts[key]) {
            this.parts[key] = [];
        }

        this.parts[key].push(value);

    } else {

        this.parts[key] = value;

    }

};

Url.prototype.removePart = function(key) {
    if(!_.isUndefined(this.parts[key])) {
        console.log("delete", key);
        delete this.parts[key];
    }
};

Url.prototype.fromPopstate = function(end) {
    if(!end) {
        this.fromPop = true;
    }
    else {
        this.fromPop = false;
    }
};
Url.prototype.compile = function() {
    var url = new URLSearchParams(window.location.search);

    _.each(this.parts, function(value, key) {
        if(_.isArray(value)) {
            value = _.uniq(value);
            value = _.reject(value, function(a){
                return(a === "" || _.isNaN(a) || _.isUndefined(a));
            });
            value = value.join(",");
        }

        if(value !== '' && !_.isNaN(value) && !_.isUndefined(value)) {
            url.set(key, value);
        } else {
            url.delete(key);
        }
    });

    if(this.reset_params) {
        for(var p of url) {
            if(_.indexOf(this.except, p[0]) === -1) {
                url.delete(p[0]);
                delete this.parts[p[0]];
            }
        }
    }

    return url;
};

Url.prototype.build = function() {
   this.url = this.compile().toString();
   return this.url;
};
Url.prototype.pushState = function() {
    var newurl = window.location.protocol
        + "//" + window.location.host + window.location.pathname
        + '?'+decodeURIComponent(this.build());
    window.history.pushState({path: newurl}, '', newurl);
    this.reset_params = false;
    this.except = [];
    this.fromPopstate(false);
};

Url.prototype.changed = function() {

};

Url.prototype.update = _.debounce(function(url) {

    this.pushState();

}, 300);
