// function to act as a class
function DropdownCellEditor () {}

// gets called once before the renderer is used
DropdownCellEditor.prototype.init = function(params) {
    // create the cell
    let items = '';
    if(params.items) {
        _.each(params.items, function(item) {
            items += '<div class="item" data-value="'+item.value+'">'+item.name+'</div>';
        });
    }

    this.eInput = $('<div class="ui selection search dropdown" style="width:120px;">' +
            '<input type="hidden">' +
            '<i class="dropdown icon"></i>' +
            '<input type="text" class="search">' +
            '<div class="default text">'+params.placeholder+'</div>' +
            '<div class="menu">' +
                items +
            '</div>'+
            '</div>');

    this.params = params;
};

// gets called once when grid ready to insert the element
DropdownCellEditor.prototype.getGui = function() {
    return this.eInput[0];
};

// focus and select can be done after the gui is attached
DropdownCellEditor.prototype.afterGuiAttached = function() {

    var name = 'name';
    var value = 'id';
    var results = 'results';
    var values = 'values';
    if(this.params.responseMap) {
        if(this.params.responseMap.name) {
            name = this.params.responseMap.name;
        }

        if(this.params.responseMap.value) {
            value = this.params.responseMap.value;
        }

        if(this.params.responseMap.results) {
            results = this.params.responseMap.results;
        }

        if(this.params.responseMap.values) {
            values = this.params.responseMap.values;
        }
    }

    var that = this;

    var onAction = function(text, value, element) {

        if(value === '__add_new' && that.params.addNew) {
            var modal = $modals.newUser;

            var title = that.params.column.colDef.headerName;
            var addNewParams = that.params.addNewParams;
            var responseMap = addNewParams.responseMap;

            modal.find(".title").html(title);
            modal.find('input[name="dwn_id"]').attr("placeholder", title + " ID");

            modal.modal("setting", "onDeny", function() {
                    _.defer(function() {
                        that.eInput.dropdown("clear");
                    });
                })
                .modal("setting", "onApprove", function() {
                    if(modal.form.form("is valid")) {
                        modal.state('saving');
                        var payload = modal.find("form").serialize();
                        console.log(payload);
                        $.ajax({
                            url: addNewParams.url,
                            method: addNewParams.method,
                            data: payload
                        }).done(function (res) {
                            modal.state('saved', res);
                            var results = _.get(res, responseMap.results);
                            var value = _.get(results, responseMap.value);
                            var text = _.get(results, responseMap.text);
                            dispatch('onNewUser', {params: that.params, response: res, value: value, text:text});
                            modal.modal("hide");
                        }).fail(function (res) {
                            console.error(res);
                            modal.state('error', res);
                        });
                    } else {
                        modal.state('invalid');
                    }

                    return false;
                });

            modal.modal('show');

        } else {
            that.eInput.dropdown("set text", text)
                .dropdown("set value", value)
                .dropdown("hide");
        }
    };


    this.dropdownSettings = {
        debug: false,
        forceSelection: false,
        saveRemoteData: false,
        duration:0,
        fields: {
            remoteValues : results, // grouping for api results
            values       : values, // grouping for all dropdown values
            name         : name,   // displayed dropdown text
            value        : value   // actual dropdown value
        },
        onChange: function(value, text, $choice) {
            var event = new CustomEvent("onDropdownChange", {detail: that.params});
            document.dispatchEvent(event);

            if(value !== '__add_new' && !_.isEmpty(value)) {
                console.log("onChange", value, that.params);
                that.params.stopEditing();
            } else {
                console.log("Opening new modal.");
            }
        },
        action: onAction
    };

    if(this.params.url) {
        this.dropdownSettings.apiSettings =  {
            cache: false,
            saveRemoteData: false,
                url: this.params.url,
                onResponse: function(response) {
                var addNew = {};
                if(that.params.addNew) {
                    addNew[name] = '<div class="ui horizontal green label"> <i class="plus icon"></i> Add New</div>';
                    addNew[value] = "__add_new";

                    response.results = _.concat([addNew], response.results);
                }
                return response;
            }
        };
    }

    this.eInput.dropdown(this.dropdownSettings);

    this.eInput.dropdown("toggle");
    this.eInput.find("input").focus();
};

// returns the new value after editing
DropdownCellEditor.prototype.getValue = function() {
    var d = {
        placeholder: this.eInput.dropdown("get placeholder text"),
        text: this.eInput.dropdown("get text"),
        value: this.eInput.dropdown("get value")
    };

    if(d.placeholder != d.text) {
        return d;
    }

    return this.params.value;
};

// any cleanup we need to be done here
DropdownCellEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
    this.eInput.dropdown("clear");
};

// if true, then this editor will appear in a popup
DropdownCellEditor.prototype.isPopup = function() {
    return true;
};