function DateCellRenderer () {}

// gets called once before the renderer is used
DateCellRenderer.prototype.init = function(params) {
    this.params = params;
    this.value = params.value;
    this.element = document.createElement('div');
    this.format = '';

    if(params.calendar)
        this.calendar = params.calendar;

    if(this.calendar.dateFormat) {
        this.format = this.calendar.dateFormat;
    }

    this.element.innerHTML = this.formatDate(this.value, this.format);
};

// gets called once when grid ready to insert the element
DateCellRenderer.prototype.getGui = function() {
    return this.element;
};


// gets called whenever the user gets the cell to refresh
DateCellRenderer.prototype.refresh = function(params) {
    // set value into cell again
    this.element.innerHTML = this.formatDate(params.value, this.format);
};