function CellRenderer () {}

// gets called once before the renderer is used
CellRenderer.prototype.init = function(params) {
    this.params = params;
    this.editable = params.colDef.editable;
    this.none = '<div class="ag-disabled">None</div>';
    // create the cell
    this.eGui = document.createElement('div');
    if(this.editable) {
        this.cell = $(params.eGridCell);
        this.eGui.innerHTML = '<span class="my-value"></span>' +
            '<div class="icons">' +
                '<div class="loading ui active inline tiny loader"></div>' +
                '<i class="saved inverted green checkmark icon"></i>' +
                '<i class="error inverted red warning sign icon"></i>' +
            '</div>';
        this.eValue = this.eGui.querySelectorAll('.my-value')[0];
    } else {
        this.eValue = this.eGui;
    }

    if(_.isUndefined(params.value)) {
        this.eValue.innerHTML = '';
    }
    else if(_.isNull(params.value) || _.isEqual(params.value, ''))
    {
        this.eValue.innerHTML = this.none;
    } else {
        this.eValue.innerHTML = this.formatValue(params);
    }
};

// gets called once when grid ready to insert the element
CellRenderer.prototype.getGui = function(params) {
    return this.eGui;
};

CellRenderer.prototype.formatDate = function(date, format) {
    var d =  moment(date);
    if(d.isValid()) {
        return d.format(format);
    }

    return '';
};

CellRenderer.prototype.isNumeric = function(value) {
    return (!isNaN(parseFloat(value)) && isFinite(value));
};

CellRenderer.prototype.formatValue = function(params) {
    if(params.type === 'percent') {
        return Math.round(params.value*10000)/ 100;
    }
    else if(this.isNumeric(params.value)) {
        let format ='0,0';
        if(params.format) {
            format = params.format;
        }
        return numeral(params.value).format(format);
    }
    else if(!_.isUndefined(params.node.floating)) {
        return '';
    }
    else if(params.type !== 'date') {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }
    else if(params.type === 'date') {
        return this.formatDate(params.value, params.format);
    }
    else if(_.get(params, 'value.text')) {
        return params.value.text;
    }
    else {
        return '<div class="ag-disabled">None</div>';
    }
};

// gets called whenever the user gets the cell to refresh
CellRenderer.prototype.refresh = function(params) {

    if(this.editable) {
        this.cell.removeClass('saving saved error');

        this.cell.addClass(params.data._status);

        if (params.data._saved || params.data._error) {
            var that = this;
            setTimeout(function () {

                if (!params.data._saving)
                    that.cell.removeClass('saved error');

            }, 3000);
        }
    }
    // set value into cell again
    //this.eValue.innerHTML = this.formatValue(params);

};