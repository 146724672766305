
// function to act as a class
function NumberCellEditor () {}

// gets called once before the renderer is used
NumberCellEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.setAttribute("type", "number");
    this.eInput.className = 'ag-cell-edit-input';
    this.eInput.value = params.value;
    this.params = params;
};

// gets called once when grid ready to insert the element
NumberCellEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumberCellEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
NumberCellEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumberCellEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
NumberCellEditor.prototype.isPopup = function() {
    return false;
};