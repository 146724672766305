

// function to act as a class
function DateCellEditor () {}

// gets called once before the renderer is used
DateCellEditor.prototype.init = function(params) {
    var placeholder = params.column.colDef.headerName;
    if(params.placeholder) {
        placeholder = params.placeholder;
    }
    // create the cell
    this.eInput = $('<div class="ui calendar" id="date_editor">' +
        '<div class="ui input left icon">' +
        '<i class="calendar icon"></i>' +
        '<input type="text" placeholder="'+placeholder+'">' +
        '</div>' +
        '</div>');

    this.params = params;
};

// gets called once when grid ready to insert the element
DateCellEditor.prototype.getGui = function() {
    return this.eInput[0];
};

// focus and select can be done after the gui is attached
DateCellEditor.prototype.afterGuiAttached = function() {
    var type = 'datetime';
    var that = this;
    if(this.params.calendar) {
        if(this.params.calendar.type) {
            type = this.params.calendar.type;
        }
    }
    this.eInput.calendar({
        type: type,
        onHidden:function(date, text) {
            that.params.stopEditing();
        }
    });
    this.eInput.calendar("set date", moment(this.params.value).format('YYYY-MM-DD HH:mm:ss'), true, false);
    this.eInput.find('input').focus();
};

// returns the new value after editing
DateCellEditor.prototype.getValue = function() {
    return moment(this.eInput.calendar("get date")).format('YYYY-MM-DD HH:mm:ss');
};

// any cleanup we need to be done here
DateCellEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateCellEditor.prototype.isPopup = function() {
    return true;
};