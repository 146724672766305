/**
 * Created by andrewlamers on 2/15/17.
 */
function ActionCellRenderer () {}

// gets called once before the renderer is used
ActionCellRenderer.prototype.init = function(params) {
    this.params = params;
    this.value = params.value;
    this.element = document.createElement('div');
    var that = this;
    var items = [];
    _.each(params.items, function(i) {
        items.push('<div class="item" data-value="'+i.value+'">'+i.name+'</div>');
    });
    this.element.innerHTML =
        '<div class="ui dropdown">' +
        '<div class="text">Actions</div>' +
        '<i class="dropdown icon"></i>' +
        '<div class="menu">' +
        items.join('') +
        '</div>' +
        '</div>';

    this.dropdown = $(this.element).find(".ui.dropdown");

};

ActionCellRenderer.prototype.setup = function() {
    var that = this;
    this.dropdown.dropdown({
        action: 'select',
        duration:0,
        onChange:function(value, text, $element) {
            that.action(value, that.params);
        }
    });
};

ActionCellRenderer.prototype.action = function(action, params) {
    var event = new CustomEvent(this.getEventName(action), {detail: params});
    document.dispatchEvent(event);
};

ActionCellRenderer.prototype.getEventName = function(action) {
    return "on"+_.upperFirst(_.camelCase(action)) + 'Action';
};

// gets called once when grid ready to insert the element
ActionCellRenderer.prototype.getGui = function() {
    var that = this;
    this.onClick = function () {
        that.setup();
        _.defer(function(){
            that.dropdown.dropdown("show");
        });
    };

    this.element.addEventListener('click', this.onClick);

    return this.element;
};

// gets called when the cell is removed from the grid
ActionCellRenderer.prototype.destroy = function() {
    this.element.removeEventListener('click', this.onClick);
};